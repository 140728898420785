<template>
  <div>
    <HomeIndex></HomeIndex>
  </div>
</template>

<script>
import HomeIndex from '@/frontmakana/HomeIndex.vue'; 

// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    HomeIndex
}
}
</script>
